<template>

  <section id="about-us" class="c-about c-section c-section--primary">

    <div class="o-content-block o-content-block--1064">

      <SectionHeader title="About Us" mod-class="o-ttl--secondary" />
      
      <div class="c-about__content">
  
        <ul class="c-about__list">
          <li 
            v-for="(item, index) in about" 
            :key="index"
            class="c-about__item"
            dzanimate
            dzanimate-once
            dzanimate-direction="right"
            dzanimate-effect="fade-slide"
            :dzanimate-delay="(index + 2) / 4"
          >
            {{ item }}
          </li>
        </ul>

        <svg 
          class="c-about__symbol" 
          width="322" 
          height="450" 
          viewBox="0 0 322 450" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          dzanimate
          dzanimate-once
          dzanimate-effect="fade-slide"
        >
          <path d="M175.112 384.896L173.698 383.481L175.112 384.895L315.035 245.048C317.999 258.941 319.655 272.826 319.655 286.53C319.655 352.272 281.81 392.303 242.846 416.14C204.792 439.42 165.875 447.102 160.827 447.972C155.78 447.102 116.863 439.42 78.8093 416.14C39.8453 392.303 2 352.272 2 286.53C2 220.523 40.1095 150.553 79.3859 96.2666C98.9915 69.1684 118.821 46.0683 134.188 29.4396C141.871 21.126 148.433 14.4349 153.289 9.6754C155.718 7.29514 157.715 5.4023 159.209 4.03364C159.86 3.43665 160.399 2.95493 160.828 2.58172C161.849 3.47291 163.467 4.95362 165.636 7.03155C168.939 10.1947 173.405 14.6289 178.737 20.1774C189.134 30.9967 202.809 46.0379 217.553 64.1331L156.918 124.735C154.31 127.342 152.868 130.497 152.854 133.785C152.839 137.077 154.257 140.262 156.918 142.922C159.58 145.582 162.767 146.999 166.059 146.984C169.348 146.97 172.504 145.529 175.112 142.922L233.559 84.5065C239.746 92.6817 245.978 101.286 252.109 110.253L156.918 205.393C154.31 208 152.868 211.155 152.854 214.443C152.839 217.734 154.257 220.92 156.918 223.58C159.58 226.24 162.767 227.657 166.059 227.642C169.348 227.628 172.504 226.187 175.112 223.58L266.459 132.283C272.107 141.4 277.55 150.786 282.661 160.376L156.918 286.051C154.31 288.658 152.868 291.813 152.854 295.101C152.839 298.392 154.257 301.578 156.918 304.238L158.332 302.823L156.918 304.238C159.58 306.898 162.767 308.314 166.059 308.3C169.348 308.286 172.504 306.845 175.112 304.238L173.698 302.823L175.112 304.238L294.669 184.745C299.395 195.205 303.626 205.819 307.198 216.51L156.918 366.708C154.31 369.316 152.868 372.471 152.854 375.758C152.839 379.05 154.257 382.236 156.918 384.895C159.58 387.556 162.767 388.972 166.059 388.958C169.348 388.943 172.504 387.503 175.112 384.896Z" stroke="#4BF2B7" stroke-width="4" class="c-about__symbol-path"></path>
        </svg>
          
  
      </div>

    </div>

  </section>

</template>

<script>
import SectionHeader from '../components/SectionHeader';

export default {
  name: 'AboutSection',
  components: {
    SectionHeader
  },
  data() {
    return {
      about: [
        'MANDI is an early-stage venture capital fund focused on accelerating the adoption of disruptive technologies in the food and agriculture industries.',
        'From our offices in Latin America and Europe, we invest in start-ups that are reshaping how we produce and consume food in the Americas and Europe.',
        'Our global network of industry leaders and our business expertise help founders accelerate the development and commercialization of next-generation technologies.'
      ]
    }
  },
}
</script>