<template>
  <h2 
    :class="['c-section__ttl o-ttl o-ttl--40', modClass]"
    dzanimate
    dzanimate-once
    dzanimate-direction="top"
    dzanimate-effect="fade-slide"
  >
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: String,
    modClass: String
  }
}
</script>