<template>
  <div :class="['c-carousel-controls', modClass]">

    <svg
      class="c-carousel-controls__btn c-carousel-controls__btn--prev" 
      @click="$emit('prevSlide')"
      width="40" 
      height="40"
    >
      <title>Prev</title>
      <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#btn-arrow-right"
      />
    </svg>

    <svg
      class="c-carousel-controls__btn c-carousel-controls__btn--next" 
      @click="$emit('nextSlide')"
      width="40" 
      height="40"
    >
      <title>Next</title>
      <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#btn-arrow-right"
      />
    </svg>

  </div>
</template>
<script>
export default {
  name: 'CarouselControls',
  props: {
    modClass: String
  }
}
</script>