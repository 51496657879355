<template>

  <section id="home" class="c-hero">
    
    <div class="c-hero__bg">

      <video class="c-hero__video" preload="auto" loop autoplay muted playsinline>
        <source type="video/mp4" src="@/assets/video/mandi-ventures-fields.mp4">
      </video>

      <a 
        class="c-hero__arrow"
        href="#about-us"
        @click.prevent="scrollTo"
      >
        <svg width="44" height="25">
          <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#ico-arrow-down"
          />
        </svg>
      </a>

    </div>

    <div class="c-hero__content">
      <h2 class="c-hero__ttl o-ttl o-ttl--40 o-ttl--white">
        <span
          dzanimate
          dzanimate-once
          dzanimate-effect="fade-slide"
          dzanimate-direction="top"
        >
          Accelerating disruptive technologies in 
        </span> 
        <br>
        <span
          dzanimate
          dzanimate-once
          dzanimate-effect="fade-slide"
          dzanimate-direction="top"
          dzanimate-delay="1"
        >
        food and agriculture
        </span>
      </h2>
    </div>

  </section>

</template>

<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'HeroSection',
  mixins: [mixins]
}
</script>