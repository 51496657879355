<template>
  <footer id="contact" class="c-main-footer">
    
    <div class="o-content-block o-content-block--1064">

      <a class="c-main-footer__logo" href="#home" @click.prevent="scrollTo">
        <svg width="166" height="50">
          <title>Mandi</title>
          <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#logo-mandi"
          />
        </svg>
      </a>

      <ul class="c-main-footer__list">

        <li class="c-main-footer__list-item c-main-footer__list-item--mail">
          <a href="mailto:contact@mandiventures.com">contact@mandiventures.com</a>
        </li>

        <li class="c-main-footer__list-item c-main-footer__list-item--marker">
          São Paulo, Brazil | Brussels, Belgium
        </li>

        <li class="c-main-footer__list-item c-main-footer__list-item--linkedin">
          <a href="https://www.linkedin.com/company/mandi-ventures/" target="_blank">mandi-ventures</a>
        </li>

      </ul>

    </div>

    <div class="c-main-footer__bar">

      <div class="o-content-block o-content-block--1064">

        <div class="c-main-footer__bar-content">

          <p class="c-main-footer__bar-copy">© {{ new Date().getFullYear() }} Mandi Ventures. All Rights Reserved.</p>
  
          <!-- <a href="#" target="_blank">Privacy Policy</a> -->
  
          <a class="c-main-footer__author" href="http://dzestudio.com.br/?utm_source=DZ-Site-Mandi&utm_medium=DZ-Button&utm_campaign=DZ-Site-Mandi" target="_blank">
            <svg width="30" height="30">
              <title>DZ Estúdio</title>
              <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#logo-dz"
              />
            </svg>
          </a>

        </div>

      </div>

    </div>

  </footer>
</template>

<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'MainFooter',
  mixins: [mixins]
}
</script>