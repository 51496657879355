<template>

  <section id="our-area-of-focus" class="c-focus-area c-section c-section--gray-light">

    <div class="o-content-block o-content-block--1064">

      <SectionHeader title="Our Area Of Focus" />

      <div v-if="!isMobile" class="o-grid o-grid--col-4">

        <div 
          v-for="(area, index) in areas" 
          :key="index"
          dzanimate
          dzanimate-once
          dzanimate-direction="top"
          dzanimate-effect="fade-slide"
          :dzanimate-delay="(index + 2) / 4"
          class="c-area-card" 
        >
          <img class="c-area-card__img u-grayscale" :src="area.image" :alt="$filters.stripHTML(area.title)" width="236" height="236">
          <h3 class="o-ttl o-ttl--20 o-ttl--regular" v-html="area.title"></h3>
        </div>

      </div>

      <div v-else>

        <carousel  
          ref="areaCarousel" 
          :settings="carouselSettings"
          :wrap-around="true"
        >
  
          <slide  
            v-for="(area, index) in areas" 
            :key="index"
          >

            <div class="c-area-card">
              <img class="c-area-card__img u-grayscale" :src="area.image" :alt="$filters.stripHTML(area.title)" width="236" height="236">
              <h3 class="o-ttl o-ttl--20 o-ttl--regular" v-html="area.title"></h3>
            </div>
            
          </slide>
  
        </carousel>
  
        <carousel-controls 
          modClass="c-carousel-controls--bottom" 
          @prevSlide="$refs.areaCarousel.prev()" 
          @nextSlide="$refs.areaCarousel.next()" 
        />

      </div>

    </div>

  </section>

</template>

<script>
import SectionHeader from '../components/SectionHeader';
import { Carousel, Slide } from 'vue3-carousel'
import CarouselControls from '@/components/CarouselControls.vue';

import img1 from '@/assets/img/area-of-focus/biotech.jpg';
import img2 from '@/assets/img/area-of-focus/fintechs-insurtechs.jpg';
import img3 from '@/assets/img/area-of-focus/logistics-supply.jpg';
import img4 from '@/assets/img/area-of-focus/new-materials-climate.jpg';
import img5 from '@/assets/img/area-of-focus/novel-food.jpg';
import img6 from '@/assets/img/area-of-focus/robotics-farming.jpg';
import img7 from '@/assets/img/area-of-focus/saas-marketplaces.jpg';
import img8 from '@/assets/img/area-of-focus/wellness-nutrition.jpg';

export default {
  name: 'FocusAreaSection',
  components: {
    SectionHeader,
    Carousel,
    Slide,
    CarouselControls
  },
  data() {
    return {
      isMobile: null,
      carouselSettings: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      areas: [
        {
          image: img1,
          title: 'Biotech <br>& Life Sciences'
        },
        {
          image: img2,
          title: 'Fintechs <br>& Insurtechs'
        },
        {
          image: img3,
          title: 'Logistics <br>& Supply Chain'
        },
        {
          image: img4,
          title: 'New Materials <br>& Climate Tech'
        },
        {
          image: img5,
          title: 'Novel Foods <br>& Ingredients'
        },
        {
          image: img6,
          title: 'Robotics <br>& Precision Farming'
        },
        {
          image: img7,
          title: 'SaaS <br>& Marketplaces'
        },
        {
          image: img8,
          title: 'Wellness <br>& Nutrition'
        },
      ]
    }
  },
  methods: {
    checkWindowSize() {
      this.isMobile = window.innerWidth < 768;
    }
  },
  beforeMount() {
    window.onload = this.checkWindowSize
  },
  mounted() {
    window.onresize = this.checkWindowSize
  }
}
</script>