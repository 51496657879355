<template>

  <section id="portfolio" class="c-portfolio c-section">

    <div class="o-content-block o-content-block--1064">

      <SectionHeader title="Portfolio" />

      <div class="o-grid o-grid--col-3">

        <div 
          v-for="(client, index) in clients"
          :key="index" 
          dzanimate
          dzanimate-once
          dzanimate-direction="top"
          dzanimate-effect="fade-slide"
          :dzanimate-delay="(index + 2) / 4"
          class="c-portfolio__logo"
        >

          <a href="#" @click.prevent="openModal(index)">

            <img class="u-grayscale" :src="client.logo.src" :alt="client.name" :width="client.logo.width" :height="client.logo.height">
            
          </a>

        </div>

      </div>

    </div>

    <modal-component
      ref="portfolioModal"
      modClass="c-modal--client"
    >
      <carousel  
        ref="portfolioCarousel" 
        :settings="carouselSettings"
        :wrap-around="true"
        v-model="currentSlide"
      >

        <slide  
          v-for="(client, index) in clients" 
          :key="index"
        >

          <div class="c-modal__wrapper">

            <div class="c-modal__media">

              <div class="c-modal__profile-card">
        
                <img class="c-modal__logo" :width="client.logo.width" :height="client.logo.height" :src="client.logo.src" :alt="client.name">
      
              </div>

            </div>

            <div class="c-modal__content">

              <div class="c-modal__info">

                <div class="c-modal__header">

                  <h3 class="c-modal__ttl o-ttl o-ttl--20">{{ client.name }}</h3>
          
                  <h4 class="c-modal__subttl">{{ client.location }}</h4>

                  <h4 class="c-modal__subttl">{{ client.type }}</h4>
                  
                </div>
          
                <div class="c-modal__body">

                  <p v-html="client.info"></p>

                  <a :href="client.link" target="_blank" class="o-btn o-btn--secondary">go to website</a>
                  
                </div>
                  
              </div>
              
            </div>

            <!-- Modal Close -->
            <svg class="c-modal__close" width="21" height="20" @click="this.$refs.portfolioModal.closeModal()">
              <title>Close Modal</title>
              <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#ico-close"
              />
            </svg>
            <!-- End: Modal Close -->

          </div>

        </slide>

      </carousel>

      <carousel-controls 
        modClass="c-carousel-controls--default" 
        @prevSlide="$refs.portfolioCarousel.prev()" 
        @nextSlide="$refs.portfolioCarousel.next()" 
      />
  
    </modal-component>

  </section>

</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { Carousel, Slide } from 'vue3-carousel'
import CarouselControls from '@/components/CarouselControls.vue';

import logo1 from '@/assets/img/portfolio/logo-fbn.png';
import logo2 from '@/assets/img/portfolio/logo-miumlab.png';
import logo3 from '@/assets/img/portfolio/logo-multus-media.png';
import logo4 from '@/assets/img/portfolio/logo-tarken.png';
import logo5 from '@/assets/img/portfolio/logo-growinco.png';
import logo6 from '@/assets/img/portfolio/logo-cultivated.png';
import logo7 from '@/assets/img/portfolio/logo-alice.png';
import logo8 from '@/assets/img/portfolio/logo-deestree.png';
import logo9 from '@/assets/img/portfolio/logo-seedz.png';

export default {
  name: 'PortfolioSection',
  components: {
    SectionHeader,
    ModalComponent,
    Carousel,
    Slide,
    CarouselControls
  },
  data() {
    return {
      showModal: false,
      currentSlide: 0,
      carouselSettings: {
        itemsToShow: 1
      },
      clients:[
        {
          logo: {
            src: logo7,
            width: '200',
            height: '102'
          },
          name: 'Alice Mushrooms',
          type: 'Foodtech',
          location: 'United States',
          info: `Alice is a lifestyle brand that produces and commercializes adaptogenic and nootropic mushroom chocolates formulated for long term health benefits as well as instant effects.`,
          link: 'https://alicemushrooms.com/'
        },
        {
          logo: {
            src: logo6,
            width: '300',
            height: '92'
          },
          name: 'Cultivated Biosciences',
          type: 'Foodtech',
          location: 'Switzerland',
          info: `Cultivated Biosciences produces and commercializes alternative dairy ingredients originated from fermentation`,
          link: 'https://www.cultivated.bio/'
        },
        {
          logo: {
            src: logo8,
            width: '300',
            height: '92'
          },
          name: 'Deestree',
          type: 'SaaS',
          location: 'France',
          info: `Deestree is a B2B SaaS platform that facilitates interactions between suppliers and buyers in the food industry, making the procurement and management of manufactured goods more efficient.`,
          link: 'https://www.deestree.fr/'
        },
        {
          logo: {
            src: logo1,
            width: '300',
            height: '92'
          },
          name: 'Farmers Business Network',
          type: 'E-commerce',
          location: 'United States',
          info: `Farmers Business Network (FBN) helps family farmers maximize profits through improved access to data, technology and direct-to-farm commerce.`,
          link: 'https://www.fbn.com/'
        },
        {
          logo: {
            src: logo5,
            width: '300',
            height: '47'
          },
          name: 'GrowinCo',
          type: 'Co-manufacturing',
          location: 'United States',
          info: `Meet GrowinCo., the leading platform connecting CPG companies, co-manufacturers, co-packers, ingredients and packaging suppliers for product development and strategic sourcing.`,
          link: 'https://growinco.com/pt/'
        },
        {
          logo: {
            src: logo2,
            width: '300',
            height: '67'
          },
          name: 'MiumLab',
          type: 'Nutrition',
          location: 'France',
          info: `Mium Labs is revolutionizing the food supplement industry by providing an easy and tasteful way to improve health and wellness.`,
          link: 'https://www.lesmiraculeux.com/'
        },
        {
          logo: {
            src: logo3,
            width: '180',
            height: '122'
          },
          name: 'Multus media',
          type: 'Biotechnology',
          location: 'United Kingdom',
          info: `Multus develops environmentally-sustainable and ethically-sourced growth media to improve efficiency in the cultured meat industry.`,
          link: 'https://www.multus.bio/'
        },
        {
          logo: {
            src: logo9,
            width: '180',
            height: '122'
          },
          name: 'Seedz',
          type: 'Marketplace',
          location: 'Brazil',
          info: `Seedz integrates the agribusiness value chain offering best in class tools to enable companies to create and manage sales incentive and loyalty programs.`,
          link: 'https://seedz.ag/'
        },
        {
          logo: {
            src: logo4,
            width: '300',
            height: '80'
          },
          name: 'Tarken',
          type: 'SaaS',
          location: 'Brazil',
          info: `Tarken is creating a data-driven future for credit, barter, and grain trade in Brazil's agriculture sector.`,
          link: 'https://tarken.com.br/'
        }
      ]
    }
  },
  methods: {
    openModal(i) {
      this.currentSlide = i;
      this.$refs.portfolioModal.openModal();
    }
  }
}
</script>