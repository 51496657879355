const mixins = {
  methods: {
    scrollTo(e) {
      const target = e.currentTarget.getAttribute('href');
      this.isNavOpen = false;
      history.pushState({}, '', target);
      window.scrollTo({
        behavior: 'smooth',
        top:
          document.querySelector(target).getBoundingClientRect().top +
          window.scrollY - 100,
      });
    },
    dzAnimate() {
      let intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const el = entry.target;
          const animateOnce = el.hasAttribute('dzanimate-once');
          const animateDelay = el.getAttribute('dzanimate-delay');
          if (entry.isIntersecting) {
            el.classList.add('js-dzanimate-animated');
            el.style.transitionDelay = el.hasAttribute('dzanimate-delay') ? 
              `${animateDelay}s` :
              '.5s';
          } else {
            !animateOnce && el.classList.remove('js-dzanimate-animated');
          }
        });
      });

      document.querySelectorAll('[dzanimate]').forEach((obj) => {
        intersectionObserver.observe(obj);
      });
    }
  }
}

export default mixins