<template>
  <header :class="[{ 'is-nav-open': isNavOpen, 'is-scrolling': isScrolling }, 'c-main-header']">
    
    <div class="o-content-block">

      <div class="c-main-header__content">

        <h1 class="c-main-header__ttl">
          <a 
            href="#home"
            @click.prevent="scrollTo"
          >
            <svg class="c-main-header__logo" width="166" height="50">
              <title>Mandi</title>
              <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#logo-mandi"
              />
            </svg>
          </a>
        </h1>
  
        <span
          @click="toggleMenu" 
          class="c-main-header__nav-trigger"
        ></span>
  
      </div>
        
      <nav class="c-main-nav">

        <ul class="c-main-nav__list">

          <li 
            v-for="(item, index) in nav"
            :key="index" 
            class="c-main-nav__item"
          >

            <a 
              :href="item.link" 
              class="c-main-nav__link"
              @click.prevent="scrollTo"
            >
                {{ item.name }}
            </a>

          </li>

        </ul>

      </nav>

    </div>

  </header>

</template>

<script>
import mixins from '@/helpers/mixins';

export default {
  name: 'MainHeader',
  mixins: [mixins],
  data() {
    return {
      isNavOpen: false,
      isScrolling: false,
      nav: [
        {
          name: 'about us',
          link: '#about-us'
        },
        {
          name: 'our area of focus',
          link: '#our-area-of-focus'
        },
        {
          name: 'our team',
          link: '#our-team'
        },
        {
          name: 'portfolio',
          link: '#portfolio'
        },
        {
          name: 'Mandi in the news',
          link: '#news'
        },
        {
          name: 'contact us',
          link: '#contact'
        }
      ]
    }
  },
  created () {
    window.addEventListener('scroll', this.updateHeader);
  },
  unmounted () {
    window.removeEventListener('scroll', this.updateHeader);
  },
  methods: {
    updateHeader() {      
      const header = document.querySelector('.c-main-header');
      const headerHeight = header.offsetHeight;          
      let scrollPos = window.pageYOffset;

      this.isScrolling = scrollPos > headerHeight;
    },
    toggleMenu() {
      this.isNavOpen = !this.isNavOpen;
    }
  }
};
</script>