<template>

  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">

    <symbol id="logo-mandi" viewBox="0 0 166 50" fill="none">
      <path d="M47.9809 18.8598C47.9809 16.9193 49.304 16.4783 50.5977 16.4783H52.0089C53.5084 16.4783 54.214 17.0663 54.7139 18.6834L58.9183 32.2668H58.9772L63.1227 18.6834C63.6226 17.0663 64.3282 16.4783 65.8277 16.4783H67.1803C68.4739 16.4783 69.7969 16.9193 69.7969 18.8598V36.0598C69.7969 37.2654 69.2088 38.412 67.6506 38.412C66.0923 38.412 65.5043 37.2654 65.5043 36.0598V22.9469H65.4454L60.9471 37.0004C60.6236 38.0002 59.8885 38.412 58.889 38.412C57.8893 38.412 57.1541 38.0002 56.8309 37.0004L52.3323 22.9469H52.2735V36.0598C52.2735 37.2654 51.6854 38.412 50.1272 38.412C48.5689 38.412 47.9809 37.2654 47.9809 36.0598V18.8598H47.9809Z" fill="currentColor"/>
      <path d="M84.3421 17.9483C84.7537 16.8604 85.5182 16.4783 86.6941 16.4783H87.8411C88.929 16.4783 89.8992 16.7429 90.4284 18.2424L96.2499 34.4427C96.4263 34.9132 96.7204 35.7657 96.7204 36.2949C96.7204 37.471 95.6912 38.412 94.4564 38.412C93.398 38.412 92.6334 38.0297 92.2806 37.089L91.0458 33.5607H83.2837L82.0194 37.089C81.6961 38.0297 81.0199 38.412 79.8731 38.412C78.5501 38.412 77.6386 37.5887 77.6386 36.2949C77.6386 35.7657 77.9327 34.9132 78.1091 34.4427L84.3421 17.9483H84.3421ZM87.2528 21.7117H87.1942L84.3421 30.2087H89.9874L87.2528 21.7117Z" fill="currentColor"/>
      <path d="M104.548 19.3008C104.548 17.3016 105.518 16.4783 107.576 16.4783C108.576 16.4783 109.458 16.8604 109.987 17.7426L118.425 31.3553H118.484V18.801C118.484 17.2427 119.366 16.4783 120.807 16.4783C122.248 16.4783 123.13 17.2427 123.13 18.801V35.4129C123.13 37.3241 122.306 38.412 120.278 38.412C118.749 38.412 118.249 38.0884 117.632 37.1182L109.252 23.8876H109.193V36.0893C109.193 37.6474 108.311 38.412 106.87 38.412C105.43 38.412 104.548 37.6474 104.548 36.0893V19.3008Z" fill="currentColor"/>
      <path d="M132.93 19.6832C132.93 17.5367 134.048 16.8604 135.753 16.8604H141.633C146.896 16.8604 151.718 19.1538 151.718 27.4451C151.718 34.1486 147.984 38.0297 141.545 38.0297H135.753C134.048 38.0297 132.93 37.3537 132.93 35.2069V19.6832ZM137.752 34.1486H141.81C145.661 34.1486 146.896 30.7088 146.896 27.4451C146.896 23.2702 145.308 20.7416 141.78 20.7416H137.752V34.1486Z" fill="currentColor"/>
      <path d="M160.633 18.8892C160.633 17.4485 161.574 16.4783 163.044 16.4783C164.514 16.4783 165.455 17.4485 165.455 18.8892V36.0011C165.455 37.4418 164.514 38.412 163.044 38.412C161.574 38.412 160.633 37.4418 160.633 36.0011V18.8892Z" fill="currentColor"/>
      <path d="M19.2894 42.609C18.7795 43.1189 18.1033 43.1293 17.583 42.609C17.0628 42.0888 17.0731 41.4124 17.583 40.9026L34.3687 24.1168C33.9274 22.7769 33.3935 21.4485 32.7927 20.1437L19.2894 33.647C18.7795 34.1569 18.1033 34.1673 17.583 33.647C17.0628 33.1268 17.0731 32.4504 17.583 31.9406L31.663 17.8605C31.0265 16.6559 30.3424 15.48 29.6313 14.3431L19.2894 24.685C18.7795 25.1949 18.1033 25.2053 17.583 24.685C17.0628 24.1648 17.0731 23.4885 17.583 22.9786L28.2842 12.2774C27.5168 11.1479 26.7344 10.0694 25.96 9.05244L19.2894 15.723C18.7795 16.2329 18.1033 16.2434 17.583 15.723C17.0628 15.2029 17.0731 14.5265 17.583 14.0166L24.4582 7.14134C21.0786 2.9706 18.1729 0.216831 17.8601 0C17.0759 0.543569 0 17.0203 0 31.8367C0 46.6531 17.0759 49.8741 17.8601 50C18.6443 49.8741 35.7202 46.6531 35.7202 31.8367C35.7202 30.1652 35.5012 28.4725 35.1144 26.784L19.2894 42.609Z" fill="#4BF2B7"/>
    </symbol>
      
    <symbol id="quote" viewBox="0 0 51 43" fill="none">
      <path d="M4.0804 4.77521L4.08032 4.77515L4.07491 4.78338C2.2243 7.59953 1.3261 11.4181 1.3261 16.1884C1.3261 21.8396 2.72063 26.7886 5.52542 31.0156C8.3284 35.2398 12.52 38.7131 18.0688 41.4485L18.5147 41.6683L18.7371 41.2236L22.4472 33.8033L22.6708 33.3561L22.2236 33.1325C15.6091 29.8252 12.2819 25.2607 12.0755 19.4375C12.2916 19.4598 12.508 19.471 12.7247 19.471C15.237 19.471 17.4394 18.6574 19.3088 17.0336C21.2152 15.4564 22.1522 13.2587 22.1522 10.5072C22.1522 7.46869 21.2693 5.01142 19.455 3.19717C17.6546 1.39679 15.3991 0.5 12.7247 0.5C8.94769 0.5 6.03986 1.91766 4.0804 4.77521ZM31.5587 4.77521L31.5586 4.77515L31.5532 4.78338C29.7026 7.59953 28.8044 11.4181 28.8044 16.1884C28.8044 21.8396 30.1989 26.7886 33.0037 31.0156C35.8067 35.2398 39.9982 38.7131 45.547 41.4485L45.993 41.6683L46.2153 41.2236L49.9255 33.8033L50.1491 33.3561L49.7019 33.1325C43.0874 29.8252 39.7602 25.2607 39.5537 19.4375C39.7699 19.4598 39.9863 19.471 40.2029 19.471C42.7152 19.471 44.9177 18.6573 46.7871 17.0336C48.6935 15.4564 49.6304 13.2587 49.6304 10.5072C49.6304 7.46869 48.7475 5.01142 46.9333 3.19717C45.1329 1.39679 42.8773 0.5 40.2029 0.5C36.426 0.5 33.5181 1.91766 31.5587 4.77521Z" stroke="#4BF2B7"/>
    </symbol>
      
    <symbol id="ico-arrow-down" viewBox="0 0 44 25" fill="none">
      <path d="M2 2L22 22L42 2" stroke="#4BF2B7" stroke-width="3"/>
    </symbol>
      
    <symbol id="btn-arrow-right" viewBox="0 0 40 40" fill="none">
      <path class="btn-path" d="M17 27L24 20L17 13" stroke="currentColor" stroke-width="2"/>
    </symbol>

    <symbol id="ico-close" viewBox="0 0 21 22" fill="none">
      <path d="M1 20.801L19.935 1M1.06497 1.19901L20 21" stroke="currentColor" stroke-width="2"/>
    </symbol>
      
    <symbol id="ico-linkedin" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0163 19.9985V12.6623C20.0163 9.07467 19.2445 6.31065 15.0487 6.31065C14.2611 6.28154 13.4804 6.46669 12.7898 6.84635C12.0991 7.22601 11.5245 7.78596 11.127 8.46651H11.0684V6.64609H7.09618V19.9985H11.2374V13.3864C11.2374 11.6451 11.5674 9.96238 13.7233 9.96238C15.8478 9.96238 15.8792 11.9492 15.8792 13.4982V19.9985H20.0163Z" fill="currentColor"/>
      <path d="M0.357214 6.64758H4.49848V20H0.357214V6.64758Z" fill="currentColor"/>
      <path d="M2.4231 2.45669e-05C1.94509 -0.00213232 1.47721 0.137782 1.07886 0.402008C0.680503 0.666235 0.369634 1.04286 0.185709 1.48408C0.00178286 1.92529 -0.046906 2.41121 0.0458219 2.88015C0.13855 3.34909 0.368513 3.7799 0.706523 4.11791C1.04453 4.45592 1.47535 4.68589 1.94429 4.77862C2.41323 4.87134 2.89914 4.82265 3.34036 4.63873C3.78158 4.4548 4.1582 4.14393 4.42243 3.74558C4.68666 3.34723 4.82657 2.87935 4.82441 2.40133C4.82405 1.76458 4.57094 1.15401 4.12069 0.703751C3.67043 0.253496 3.05986 0.000385847 2.4231 2.45669e-05Z" fill="currentColor"/>
    </symbol>
      
    <symbol id="logo-dz" viewBox="0 0 30 30" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.98828 1.98828H28.0124V28.0124H1.98828V1.98828ZM0 30H30V0H0V30Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2891 18.2898L20.6023 11.8199H15.2891V10.0488H23.0602V11.8199L17.6746 18.2898H23.0602V20.0609H15.2891V18.2898ZM12.5419 19.6994C11.8584 19.9466 11.1361 20.069 10.4094 20.0609H6.79492V10.0488H10.4094C11.9275 10.0488 13.1564 10.4464 13.9877 11.2416C14.819 12.0368 15.2889 13.2296 15.2889 14.8199C15.2943 15.5528 15.197 16.2828 14.9997 16.9886C14.828 17.6039 14.5191 18.1724 14.0961 18.6512C13.6709 19.1217 13.1374 19.4815 12.5419 19.6994M12.6869 17.2784C12.8656 16.9997 12.9998 16.6948 13.0844 16.3747C13.2092 15.9277 13.2701 15.4653 13.2652 15.0012C13.2742 14.5272 13.2257 14.0539 13.1206 13.5916C13.0373 13.2358 12.8769 12.9027 12.6507 12.6157C12.4282 12.3544 12.1418 12.1552 11.8194 12.0374C11.4035 11.8826 10.9618 11.8089 10.5182 11.8205H8.71094V18.2904H10.9881L11.5664 18.1458L12.1808 17.8205C12.382 17.6731 12.5537 17.4892 12.6869 17.2783" fill="currentColor"/>
    </symbol>
      
  </svg>

</template>

<script>
  export default {
    name: 'SvgAssets'
  }
</script>
