<template>

  <transition name="modal">

    <div :class="['c-modal', modClass]" v-if="showModal">

      <div class="c-modal__carousel">

        <slot></slot>

      </div>
      
    </div>
    
  </transition>

</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    modClass: String
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    openModal() {
      document.querySelector('html').classList.add('is-fixed-content');
      this.showModal = true;
    },
    closeModal() {
      document.querySelector('html').classList.remove('is-fixed-content');
      this.showModal = false
    }
  }
}
</script>