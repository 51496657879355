<template>
  <transition name="fade">
    <PageLoader v-if="!isLoaded" />
  </transition>
  <MainHeader />
  <HeroSection />
  <AboutSection />
  <FocusAreaSection />
  <TeamSection />
  <TestimonialSection />
  <PortfolioSection />
  <NewsSection />
  <MainFooter />
  <SvgsAssets />
</template>

<script>
import mixins from '@/helpers/mixins';
import PageLoader from './components/PageLoader.vue';
import MainHeader from './components/MainHeader.vue';
import MainFooter from './components/MainFooter.vue';
import HeroSection from './sections/HeroSection.vue';
import AboutSection from './sections/AboutSection.vue';
import FocusAreaSection from './sections/FocusAreaSection.vue';
import TeamSection from './sections/TeamSection.vue';
import TestimonialSection from './sections/TestimonialSection.vue';
import PortfolioSection from './sections/PortfolioSection.vue';
import NewsSection from './sections/NewsSection.vue';
import SvgsAssets from './components/SvgAssets.vue';

export default {
  name: 'App',
  components: {
    PageLoader,
    MainHeader,
    MainFooter,
    HeroSection,
    AboutSection,
    FocusAreaSection,
    TeamSection,
    TestimonialSection,
    PortfolioSection,
    NewsSection,
    SvgsAssets
  },
  mixins: [mixins],
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        setTimeout(() => {
          this.isLoaded = true;
          this.dzAnimate();
        }, 1200);
      } 
    }
  }
}
</script>
