<template>

  <section id="news" class="c-news c-section c-section--gray-light">

    <div class="o-content-block">

      <SectionHeader title="Mandi in the news" />

      <div class="o-content-block__offset">

        <div class="c-news__carousel">

          <carousel  
            ref="newsCarousel" 
            dzanimate
            dzanimate-once
            dzanimate-direction="top"
            dzanimate-effect="fade-slide"
            dzanimate-delay="1"
            :settings="carouselSettings"
            :breakpoints="carouselBreakpoints"
          >
    
            <slide  
              v-for="(post, index) in news"
              :key="index" 
            >
    
              <div class="c-post">
    
                <div class="c-post__img">
      
                  <a :href="post.link" target="_blank">
        
                    <img :src="post.image" :alt="post.title" width="350" height="250">
      
                    <span class="c-post__date">{{ post.date }}</span>
                    
                  </a>
                  
                </div>
      
                <div class="c-post__content">
      
                  <span class="c-post__label">{{ post.label }}</span>
      
                  <h3 class="c-post__ttl">{{ post.title }}</h3>
      
                  <a :href="post.link" class="c-post__link" target="_blank">See more</a>
      
                </div>
                
              </div>
    
            </slide>
    
          </carousel>

        </div>
        
      </div>
        
      <carousel-controls 
        modClass="c-carousel-controls--bottom" 
        @prevSlide="$refs.newsCarousel.prev()" 
        @nextSlide="$refs.newsCarousel.next()" 
      />
      
    </div>

  </section>

</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue';
import { Carousel, Slide } from 'vue3-carousel'
import CarouselControls from '@/components/CarouselControls.vue';

import image1 from '@/assets/img/news/news-1.jpg';
import image2 from '@/assets/img/news/news-2.jpg';
import image3 from '@/assets/img/news/news-3.jpg';
import image4 from '@/assets/img/news/news-4.jpg';
import image5 from '@/assets/img/news/news-5.jpg';
import image6 from '@/assets/img/news/news-6.jpg';
import image7 from '@/assets/img/news/news-7.jpg';
import image8 from '@/assets/img/news/news-8.jpg';
import image9 from '@/assets/img/news/news-9.jpg';
import image10 from '@/assets/img/news/news-10.jpg';
import image11 from '@/assets/img/news/news-11.jpg';
import image12 from '@/assets/img/news/news-12.jpg';
import image13 from '@/assets/img/news/news-13.jpg';

export default {
  name: 'NewsSection',
  components: {
    SectionHeader,
    Carousel,
    Slide,
    CarouselControls
  },
  data() {
    return {
      carouselSettings: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      carouselBreakpoints: {
        // 480px and up
        480: {
          itemsToShow: 1.5,
          snapAlign: 'center'
        },
        700: {
          itemsToShow: 2.2,
          snapAlign: 'center'
        },
        1024: {
          itemsToShow: 3.2,
          snapAlign: 'center'
        },
        1600: {
          itemsToShow: 4.2,
          snapAlign: 'center'
        }
      },
      news:[
        {
          date: '19/04/2024',
          image: image13,
          label: 'Article',
          title: 'Tarken se junta à Aliare para trazer mais dados do produtor aos credores',
          link: 'https://agfeed.com.br/agtech/tarken-se-junta-a-aliare-para-trazer-mais-dados-do-produtor-aos-credores/'
        },
        {
          date: '10/04/2024',
          image: image10,
          label: 'Article',
          title: 'Mandi Ventures vê fim do "inverno das startups" e busca novas investidas no agro',
          link: 'https://agfeed.com.br/agtech/mandi-ventures-ve-fim-do-inverno-das-startups-e-busca-novas-investidas-no-agro/#'
        },
        {
          date: '05/03/2024',
          image: image11,
          label: 'Article',
          title: 'Cultivated Biosciences poised to take its yeast cream to market in 2025',
          link: 'https://techcrunch.com/2024/03/05/cultivated-biosciences-plant-based-cream/'
        },
        {
          date: '16/01/2024',
          image: image12,
          label: 'Article',
          title: 'UK Biotech Multus Unveils World’s First Commercial-Scale Serum-Free Growth Media Facility',
          link: 'https://vegconomist.com/cultivated-cell-cultured-biotechnology/cultivated-meat/uks-biotech-multus-worlds-first-commercial-scale-serum-free-growth-media-facility/'
        },
        {
          date: '23/11/2023',
          image: image9,
          label: 'Article',
          title: 'GrowinCo: a startup que quer popularizar a ‘comanufatura’',
          link: 'https://braziljournal.com/growinco-a-startup-que-quer-popularizar-a-comanufatura/'
        },
        {
          date: '06/09/2023',
          image: image8,
          label: 'Article',
          title: 'Venture Capital ensaia retomada mais racional',
          link: 'https://valor.globo.com/financas/noticia/2023/09/06/venture-capital-ensaia-retomada-mais-racional.ghtml'
        },
        {
          date: '17/01/2023',
          image: image7,
          label: 'Article',
          title: 'Multus Biotechnology closes $9.5M funding to bring cultivated meat closer to price parity',
          link: 'https://www.labiotech.eu/trends-news/multus-biotechnology-funding-cultivated-meat-price-parity/'
        },
        {
          date: '16/01/2023',
          image: image6,
          label: 'Article',
          title: 'A agricultura celular faz carnes em laboratório. A Multus quer baratear o custo',
          link: 'https://braziljournal.com/a-agricultura-celular-faz-carnes-em-laboratorio-a-multus-quer-baratear-o-custo/'
        },
        {
          date: '18/07/2022',
          image: image1,
          label: 'Article',
          title: 'A aposta da Mandi Ventures em gummies vitaminadas',
          link: 'https://pipelinevalor.globo.com/startups/noticia/a-aposta-da-mandi-ventures-em-gummies-vitaminadas.ghtml'
        },
        {
          date: '16/06/2022',
          image: image2,
          label: 'Article',
          title: 'Na Mandi Ventures, uma "milagrosa" bala de goma para saciar a fome de investimentos',
          link: 'https://neofeed.com.br/blog/home/na-mandi-ventures-uma-milagrosa-bala-de-goma-para-saciar-a-fome-de-investimentos/'
        },
        {
          date: '18/03/2022',
          image: image3,
          label: 'Article',
          title: 'Mandi, o VC das startups que querem alimentar o mundo.',
          link: 'https://braziljournal.com/mandi-o-vc-das-startups-que-querem-alimentar-o-mundo/'
        },
        {
          date: '01/02/2022',
          image: image4,
          label: 'Article',
          title: 'PIPELINE: Mandi apresenta agtech FBN a investidores',
          link: 'https://valor.globo.com/agronegocios/noticia/2022/02/01/pipeline-mandi-apresenta-agtech-fbn-a-investidores.ghtml'
        },
        {
          date: '03/09/2020',
          image: image5,
          label: 'Article',
          title: 'O Moreira Salles que vai ao campo para "semear e colher" unicórnios',
          link: 'https://neofeed.com.br/blog/home/o-moreira-salles-que-vai-ao-campo-para-semear-e-colher-unicornios/'
        }
      ]
    }
  }
}
</script>