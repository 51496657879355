<template>

  <section class="c-section c-testimonial">

    <div class="o-content-block">

      <blockquote 
        dzanimate
        dzanimate-once
        dzanimate-direction="top"
        dzanimate-effect="fade-slide"
        dzanimate-speed="medium"
        class="c-testimonial__quote"
      >
        Building a business is not a sprint, it’s a marathon, and we are ready to support our portfolio companies throughout their entire journey.
      </blockquote>

    </div>

  </section>

</template>

<script>
export default {
  name: 'TestimonialSection'
}
</script>